<template>
  <span />
</template>
<script setup>
const { readable: deviceStoreReadable } = useDeviceStore()
const editableChromExtensionPrefs = useChromeExtensionStore().editable
const editableExtension = editableChromExtensionPrefs.extension()
const isMobile = computed(() => deviceStoreReadable.devicePrefs.isMobileDevice)
const isChromeExt = computed(() => editableExtension.value)
onMounted(() => {
  const showCookieScript = !isMobile.value && !isChromeExt.value
  const cookieScriptUrl = import.meta.env.VITE_COOKIE_SCRIPT_URL
  if (showCookieScript && cookieScriptUrl) {
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', cookieScriptUrl)
    document.head.appendChild(scriptElement)
  }
})
</script>
